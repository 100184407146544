import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextXHRBackend from 'i18next-xhr-backend';
import * as moment from 'moment-timezone';
import * as dompurify from 'dompurify';
import Component from 'lib/component';
import { tif } from 'lib/template-operators';
import { Track } from 'services/track';
import translations from 'i18n/translations';
import svg from 'lib/svgs';
import style from './played-tracks.css';

export default class PlayedTracks extends Component {
  private readonly onChangeDate: Function;
  private readonly displayBuyLink: boolean;
  private readonly displayDate: boolean;
  private dateChanged = false;
  private date: moment.Moment;
  public constructor(
    content: Track[],
    displayDate: boolean,
    displayBuyLink: boolean,
    onChangeDate: Function,
    date: moment.Moment,
  ) {
    super({ className: style.articleCard });

    this.onChangeDate = onChangeDate;
    this.displayBuyLink = displayBuyLink;
    this.displayDate = displayDate;
    this.date = date;
    i18next
      .use(LanguageDetector)
      .use(i18nextXHRBackend)
      .init(
        {
          fallbackLng: 'en',
          debug: false,
          resources: translations,
        },
        () => {
          moment.locale(i18next.language);
          this.init(content);
        },
      );
  }

  init(tracks: Track[]): void {
    let html = '';
    if (this.displayDate) {
      html = `<div class="${style.form}"><div class="${
        style.formInput
      }"><label>${i18next.t(
        'DATE',
      )}</label><input id="rk-played-tracks-date" type="date" value="${this.date.format(
        'YYYY-MM-DD',
      )}"></div><div class="${style.formInput}"><label>${i18next.t(
        'HOUR',
      )}</label><input id="rk-played-tracks-time" type="time" value="${this.date.format(
        'HH:mm',
      )}"></div>
        <div class="${style.formInput}"><button class="${
        style.searchButton
      }" id="rk-played-tracks-search" ><span class="${style.searchIcon}">${
        svg.search
      }</span>${i18next.t('SEARCH')}</button>
        </div>
       </div>`;
    }
    html += '<div id="rk-played-tracks-table"></div>';
    this.updateHtml(html);
    this.createTable(tracks);
    setInterval(() => {
      if (!this.dateChanged) {
        this.onChangeDate();
      }
    }, 60000);
  }

  createTable(tracks: Track[]): void {
    let html = '';
    tracks.forEach(track => {
      let line = `<div class="${style.trackLine}"> ${tif(track.cover)(
        `<img src='${track.cover}' alt='${track.title}' class='${style.image}' />`,
      )}${tif(!track.cover)(svg.cover)}<div class="${
        style.infos
      }"><div class="${style.date}">${svg.clock}  <span>${this.getDisplayDate(
        moment(track.startedAt),
      )}</span></div> <div class="${style.titleBlock}"> <div class="${
        style.title
      }">${track.title}</div>
        <div class="${style.artistContainer}">
        ${tif(track.artist)(
          `<div class="${style.artist}">${track.artist}</div>`,
        )}
        </div>
         </div></div>`;
      if (this.displayBuyLink && track.buyLink) {
        line += `<div class="${style.buy}"><a target="_blank" href="${
          track.buyLink
        }"><span class="${style.iconButton}">${svg.cart}</span>${i18next.t(
          'BUY',
        )}</a></div>`;
      }
      line += `</div>`;
      html += line;
    });

    const checkExist = setInterval(() => {
      const table = this.$element.querySelector(
        '#rk-played-tracks-table',
      ) as HTMLDivElement;
      if (table) {
        clearInterval(checkExist);
        table.innerHTML = dompurify.sanitize(html);
        this.setActions();
      }
    }, 100);
  }

  updateTracks(tracks: Track[]): void {
    const table = this.$element.querySelector(
      '#rk-played-tracks-table',
    ) as HTMLElement;
    if (table) {
      table.innerHTML = '';
      this.createTable(tracks);
    } else {
      this.dateChanged = true;
    }
  }

  setActions(): void {
    const dateInput = this.$element.querySelector(
      '#rk-played-tracks-date',
    ) as HTMLInputElement;
    const timeInput = this.$element.querySelector(
      '#rk-played-tracks-time',
    ) as HTMLInputElement;
    const searchButton = this.$element.querySelector(
      '#rk-played-tracks-search',
    ) as HTMLButtonElement;
    if (dateInput && timeInput && searchButton) {
      searchButton.onclick = (): void => {
        const date = moment(dateInput.value);
        const time = moment(timeInput.value, 'HH:mm');
        date.set('hour', time.hour());
        date.set('minute', time.minute());
        this.onChangeDate(date);
        this.dateChanged = true;
      };
    }
  }

  getDisplayDate = (date: moment.Moment): string => {
    const daysDiff = moment().diff(date.clone().startOf('day'), 'days');
    switch (daysDiff) {
      case 0:
        return i18next.t('TODAY', { hour: date.format('HH:mm') });
      case 1:
        return i18next.t('YESTERDAY', { hour: date.format('HH:mm') });
      default: {
        const dayFormatted = i18next.t('DATE_AT');
        return i18next.t('OTHER_DAYS', {
          day: date.format(dayFormatted),
          hour: date.format('HH:mm'),
        });
      }
    }
  };
}
