const svg = {
  clock:
    '<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
    '\t viewBox="0 0 10 10" style="enable-background:new 0 0 10 10;" xml:space="preserve">\n' +
    '<style type="text/css">\n' +
    '\t.st0{fill:#ACACAC;}\n' +
    '</style>\n' +
    '<title>clock</title>\n' +
    '<desc>Created with Sketch.</desc>\n' +
    '<g id="Partage">\n' +
    '\t<g id="Partage---Titres-diffusés" transform="translate(-1142.000000, -261.000000)">\n' +
    '\t\t<g id="Group-2" transform="translate(1035.000000, 150.000000)">\n' +
    '\t\t\t<g id="Group-23">\n' +
    '\t\t\t\t<g id="Group-21" transform="translate(25.000000, 44.000000)">\n' +
    '\t\t\t\t\t<g id="Group-18" transform="translate(0.000000, 62.000000)">\n' +
    '\t\t\t\t\t\t<g id="Group-16">\n' +
    '\t\t\t\t\t\t\t<g id="Group-4" transform="translate(82.000000, 0.000000)">\n' +
    '\t\t\t\t\t\t\t\t<g id="Group-17">\n' +
    '\t\t\t\t\t\t\t\t\t<g id="clock" transform="translate(0.000000, 5.000000)">\n' +
    '\t\t\t\t\t\t\t\t\t\t<path id="Shape" class="st0" d="M5,0C2.2,0,0,2.2,0,5s2.2,5,5,5s5-2.2,5-5S7.8,0,5,0z M5,9C2.8,9,1,7.2,1,5s1.8-4,4-4\n' +
    '\t\t\t\t\t\t\t\t\t\t\ts4,1.8,4,4S7.2,9,5,9z M6.2,6.9L4.5,5.7c-0.1,0-0.1-0.1-0.1-0.2V2.2c0-0.1,0.1-0.2,0.2-0.2h0.6c0.1,0,0.2,0.1,0.2,0.2V5\n' +
    '\t\t\t\t\t\t\t\t\t\t\tl1.3,1C7,6.1,7,6.2,7,6.4L6.6,6.9C6.5,7,6.4,7,6.2,6.9z"/>\n' +
    '\t\t\t\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t</g>\n' +
    '\t\t\t\t</g>\n' +
    '\t\t\t</g>\n' +
    '\t\t</g>\n' +
    '\t</g>\n' +
    '</g>\n' +
    '</svg>',
  search:
    '<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
    '\t viewBox="0 0 10 11" style="enable-background:new 0 0 10 11;" xml:space="preserve">\n' +
    '<style type="text/css">\n' +
    '\t.st0{fill:#474747;}\n' +
    '</style>\n' +
    '<title>search_icone</title>\n' +
    '<desc>Created with Sketch.</desc>\n' +
    '<g id="Partage">\n' +
    '\t<g id="Partage---Titres-diffusés" transform="translate(-1342.000000, -206.000000)">\n' +
    '\t\t<g id="Group-2" transform="translate(1035.000000, 150.000000)">\n' +
    '\t\t\t<g id="Group-23">\n' +
    '\t\t\t\t<g id="Group-21" transform="translate(25.000000, 44.000000)">\n' +
    '\t\t\t\t\t<g id="BTN-Annuler-Copy" transform="translate(270.000000, 0.000000)">\n' +
    '\t\t\t\t\t\t<g id="BTN-Ajouter-Copy">\n' +
    '\t\t\t\t\t\t\t<g id="search_icone" transform="translate(12.000000, 12.000000)">\n' +
    '\t\t\t\t\t\t\t\t<path id="Shape" class="st0" d="M9.9,8.9l-1.9-2C7.8,6.8,7.7,6.7,7.6,6.7H7.3C7.8,6,8.1,5.1,8.1,4.2C8.1,1.9,6.3,0,4.1,0\n' +
    '\t\t\t\t\t\t\t\t\tS0,1.9,0,4.2s1.8,4.2,4.1,4.2C5,8.3,5.9,8,6.6,7.5v0.3c0,0.1,0,0.3,0.1,0.3l1.9,2c0.2,0.2,0.5,0.2,0.7,0l0.6-0.6\n' +
    '\t\t\t\t\t\t\t\t\tC10,9.4,10,9.1,9.9,8.9z M4.1,6.7c-1.4,0-2.5-1.1-2.5-2.6c0-1.4,1.1-2.6,2.5-2.6c1.4,0,2.5,1.1,2.5,2.6\n' +
    '\t\t\t\t\t\t\t\t\tC6.6,5.6,5.4,6.7,4.1,6.7z"/>\n' +
    '\t\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t</g>\n' +
    '\t\t\t\t</g>\n' +
    '\t\t\t</g>\n' +
    '\t\t</g>\n' +
    '\t</g>\n' +
    '</g>\n' +
    '</svg>',
  cart:
    '<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
    '\t viewBox="0 0 14 12" style="enable-background:new 0 0 14 12;" xml:space="preserve">\n' +
    '<style type="text/css">\n' +
    '\t.st0{fill:#474747;}\n' +
    '</style>\n' +
    '<title>shopping-cart</title>\n' +
    '<desc>Created with Sketch.</desc>\n' +
    '<g id="Partage">\n' +
    '\t<g id="Partage---Titres-diffusés" transform="translate(-1524.000000, -282.000000)">\n' +
    '\t\t<g id="Group-2" transform="translate(1035.000000, 150.000000)">\n' +
    '\t\t\t<g id="Group-23">\n' +
    '\t\t\t\t<g id="Group-21" transform="translate(25.000000, 44.000000)">\n' +
    '\t\t\t\t\t<g id="BTN-Annuler" transform="translate(452.000000, 77.000000)">\n' +
    '\t\t\t\t\t\t<g id="BTN-Ajouter-Copy">\n' +
    '\t\t\t\t\t\t\t<g id="shopping-cart" transform="translate(12.000000, 11.000000)">\n' +
    '\t\t\t\t\t\t\t\t<path id="Path" class="st0" d="M12.3,7l1.1-4.8c0.1-0.3-0.2-0.7-0.5-0.7H3.7l-0.2-1C3.4,0.2,3.2,0,3,0H0.6\n' +
    '\t\t\t\t\t\t\t\t\tC0.3,0,0,0.2,0,0.6v0.4c0,0.3,0.3,0.6,0.6,0.6h1.6l1.6,7.9C3.4,9.6,3.2,10,3.2,10.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3\n' +
    '\t\t\t\t\t\t\t\t\tc0-0.4-0.2-0.7-0.4-0.9h4.9c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.5-0.3-1-0.7-1.2\n' +
    '\t\t\t\t\t\t\t\t\tl0.1-0.6c0.1-0.3-0.2-0.7-0.5-0.7H5.1L4.9,7.4h6.8C12,7.4,12.3,7.2,12.3,7z"/>\n' +
    '\t\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t\t</g>\n' +
    '\t\t\t\t\t</g>\n' +
    '\t\t\t\t</g>\n' +
    '\t\t\t</g>\n' +
    '\t\t</g>\n' +
    '\t</g>\n' +
    '</g>\n' +
    '</svg>\n',
  cover:
    '<svg version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">\n' +
    '<defs>\n' +
    '<linearGradient id="a" y1="100%">\n' +
    '<stop stop-color="#474747" offset="0"/>\n' +
    '<stop stop-color="#ACACAC" offset="1"/>\n' +
    '</linearGradient>\n' +
    '</defs>\n' +
    '<g fill-rule="evenodd">\n' +
    '<rect width="500" height="500" fill="url(#a)"/>\n' +
    '<path d="m348.92 131v196.58c-0.004235 18.858-12.673 35.362-30.889 40.239-18.216 4.8777-37.437-3.0871-46.864-19.419-9.427-16.332-6.7096-36.96 6.6256-50.294 13.335-13.334 33.963-16.049 50.294-6.62v-123.53l-93.75 44.122v115.5c-0.004235 18.858-12.673 35.362-30.889 40.239-18.216 4.8777-37.437-3.0871-46.864-19.419-9.427-16.332-6.7096-36.96 6.6256-50.294 13.335-13.334 33.963-16.049 50.294-6.62v-96.74l135.42-63.75zm-157.08 177c-11.046 0-20 8.9543-20 20s8.9543 20 20 20 20-8.9543 20-20-8.9543-20-20-20zm115 0c-11.046 0-20 8.9543-20 20s8.9543 20 20 20 20-8.9543 20-20-8.9543-20-20-20z" fill="#F2F2F2" fill-rule="nonzero"/>\n' +
    '</g>\n' +
    '</svg>\n',
};

export { svg as default };
