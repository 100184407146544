const translations = {
  en: {
    translation: {
      DATE: 'Date',
      HOUR: 'Hour',
      TODAY: 'Today at {{hour}}',
      YESTERDAY: 'Yesterday at {{hour}}',
      DATE_AT: 'MMMM Do',
      OTHER_DAYS: '{{day}} at {{hour}}',
      BUY: 'Buy',
      SEARCH: 'Search',
    },
  },
  fr: {
    translation: {
      DATE: 'Date',
      HOUR: 'Heure',
      TODAY: "Aujourd'hui à {{hour}}",
      YESTERDAY: 'Hier à {{hour}}',
      DATE_AT: 'D MMMM',
      OTHER_DAYS: 'Le {{day}} à {{hour}}',
      BUY: 'Acheter',
      SEARCH: 'Rechercher',
    },
  },

  de: {
    translation: {
      DATE: 'Datum',
      HOUR: 'Uhrzeit',
      TODAY: 'Heute um {{hour}}',
      YESTERDAY: 'Gestern um {{hour}}',
      DATE_AT: 'D MMMM',
      OTHER_DAYS: '{{day}} um {{hour}}',
      BUY: 'Kaufen',
      SEARCH: 'Suche',
    },
  },
  es: {
    translation: {
      DATE: 'Fecha',
      HOUR: 'Hora',
      TODAY: 'Hoy a las {{hour}}',
      YESTERDAY: 'Ayer a las {{hour}}',
      DATE_AT: 'D MMMM',
      OTHER_DAYS: '{{day}} a las {{hour}}',
      BUY: 'Comprar',
      SEARCH: 'Buscar',
    },
  },
  it: {
    translation: {
      DATE: 'Data',
      HOUR: 'Ora',
      TODAY: 'Oggi alle {{hour}}',
      YESTERDAY: 'Ieri alle {{hour}}',
      DATE_AT: 'D MMMM',
      OTHER_DAYS: '{{day}} alle {{hour}}',
      BUY: 'Acquista',
      SEARCH: 'Ricerca',
    },
  },
  pt: {
    translation: {
      DATE: 'Data',
      HOUR: 'Hora',
      TODAY: 'Hoje às {{hour}}',
      YESTERDAY: 'Ontem às {{hour}}',
      DATE_AT: 'MMMM Do',
      OTHER_DAYS: '{{day}} às {{hour}}',
      BUY: 'Comprar',
      SEARCH: 'Pesquisar',
    },
  },
};

export { translations as default };
