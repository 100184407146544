import Component from 'lib/component';
import ErrorAlert from 'components/error-alert';
import * as moment from 'moment-timezone';
import style from './main.css';
import TrackService, { Track } from '../services/track';
import PlayedTracks from '../components/played-tracks';
import Loader from '../components/loader';

export default class Main extends Component {
  public errorAlert = new ErrorAlert();
  public content = new Component();
  public loader = new Loader();
  displayDate = true;
  displayBuyLink = true;
  numberOfTracks = 3;
  private readonly slug: string = '';
  private date: moment.Moment = moment();
  private playedTracks: PlayedTracks | undefined;

  public constructor(slug: string, count: string, date: string, buy: string) {
    super({ className: style.mainView });

    this.add(this.loader);
    if (!slug) {
      this.setupErrorView();
    } else {
      this.slug = slug;
      this.numberOfTracks = Number.parseInt(count, 10);
      this.displayDate = date !== '0';
      this.displayBuyLink = buy !== '0';
      this.fetchCurrentTrack();
      this.setupViews();
    }
  }

  private setupViews(): void {
    this.errorAlert.hide();
    this.add(this.errorAlert);
    this.add(this.content);
  }

  private setupErrorView(): void {
    this.loader.hide();
    this.errorAlert.show();
    this.add(this.errorAlert);
  }

  private fetchCurrentTrack(): void {
    const trackService = new TrackService();
    const rez = trackService.fetchPlayedTracks(
      this.slug,
      this.numberOfTracks,
      this.date.toISOString(),
    );
    rez.subscribe(this.displayPlayedTracks.bind(this), () =>
      this.setupErrorView(),
    );
  }

  private displayPlayedTracks(result: Track[]): void {
    this.loader.hide();
    if (!this.playedTracks) {
      this.content.clear();
      this.playedTracks = new PlayedTracks(
        result,
        this.displayDate,
        this.displayBuyLink,
        this.onChangedDate,
        this.date,
      );
      this.content.add(this.playedTracks);
    } else {
      this.playedTracks.updateTracks(result);
    }
  }

  onChangedDate = (date: moment.Moment = moment()): void => {
    this.date = date;
    this.fetchCurrentTrack();
  };
}
